import "../styles/globals.css";
import nookies from "nookies";
import { anonymous } from "../provider/auth";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import Script from "next/script";
import * as ga from "../components/ga";
import { RecoilRoot } from "recoil";
import { DefaultSeo } from "next-seo";
import Analytics from "analytics";
import googleTagManager from "@analytics/google-tag-manager";

const analytics = Analytics({
  app: "awesome-app",
  plugins: [
    googleTagManager({
      containerId: "GTM-5J3QDV69",
    }),
  ],
});

/* Track a page view */
analytics.page();

/* Track a custom event */
analytics.track("cartCheckout", {
  item: "pink socks",
  price: 20,
});

config.autoAddCss = false;

const cookieOptions = {
  path: "/",
  sameSite: "none",
  maxAge: 365 * 24 * 60 * 60,
  secure: true,
};

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const titleName = router.pathname.includes("/en")
    ? "Easy RFQ & Fast Quoting Service"
    : "전자부품 1등 쇼핑몰 판다파츠";

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${ga.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${ga.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <RecoilRoot>
        <DefaultSeo
          titleTemplate={titleName}
          defaultTitle={titleName}
          description="전자부품 쇼핑몰, 전자부품 이커머스, 글로벌 유통사 최대 제휴, 자동 견적, 정품 판매, 실시간 가격비교, 실재고, 전자부품 대리점, 항공특송"
          keywords="전자부품 쇼핑몰, 전자부품 사이트, 전자부품 대리점, 전자 부품 공인 유통사, 전자부품 견적, 데이터시트, 실 재고, 마우저, mouser, 디지키, digikey, 애로우, arrow, 로체스터, rochester, 올파츠, allparts, octopart, findchips, 디바이스마트, 엘레파츠, 아이씨뱅크, 아이씨뱅큐, TI, TE, XILINX, INTEL, ALTERA, IDT, ON SEMI, Infineon, NXP, Analog Devices, Nvdia, 젯슨나노, 라즈베리파이, Raspberry pi, 아두이노, Arduino, DF Robot, Sparkfun"
          openGraph={{
            type: "website",
            title: "판다파츠 - 전자부품 온라인 구매 플랫폼",
            locale: "ko",
            site_name: "판다파츠",
            url: "https://pandaparts.co.kr",
          }}
        />
        <Component {...pageProps} />
      </RecoilRoot>
    </>
  );
}

MyApp.getInitialProps = async (context) => {
  const { ctx, Component } = context; // next에서 넣어주는 context
  let pageProps = {};

  const { token, isLogin } = nookies.get(ctx);
  // SSR 환경일 때만 서버사이드에서 쿠키를 넣어주고, 클라이언트 환경일 때는 넣지 않음
  if (!isLogin || !token || token === "undefined") {
    //로그인이 안되어 있으면 해당 데이터 심기
    // 토큰 데이터 가지고 오기
    try {
      nookies.set(ctx, "isLogin", Number(0), cookieOptions);
      const token = await anonymous();
      nookies.set(ctx, "token", token, cookieOptions);
    } catch (e) {
      throw new Error("쿠키 심기 또는 토큰 심기 오류 발생");
    }
  }
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return { pageProps };
};

export default MyApp;
